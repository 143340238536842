import React from "react";
import { Box } from "grommet";

const TerraformIcon = () => {
  return (
    <Box>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 256 288"
        version="1.1"
        preserveAspectRatio="xMidYMid"
      >
        <g>
          <path
            d="M87.3483423,49.909048 L169.843948,97.0452411 L169.843948,189.637714 L87.3483423,142.501392 L87.3483423,49.909048 L87.3483423,49.909048 Z M174.222231,189.222177 L256,142.226168 L256,50.2322794 L174.222231,96.9051941 L174.222231,189.222177 L174.222231,189.222177 Z M0,92.5923443 L82.4955975,139.728675 L82.4955975,47.1363217 L0,0 L0,92.5923443 L0,92.5923443 Z M87.3483423,240.540449 L169.843948,287.676771 L169.843948,194.761062 L87.3483423,147.62486 L87.3483423,240.540449 L87.3483423,240.540449 Z"
            fill="#000000"
          ></path>
        </g>
      </svg>
    </Box>
  );
};

export default TerraformIcon;
