import React from "react";
import { Box } from "grommet";

const WebpackIcon = () => {
  return (
    <Box style={{ position: "relative", left: "1px" }}>
      <svg version="1.1" viewBox="0 0 512 512" width="1em" height="1em">
        <path d="M449.12146,124.207901L223.9439697,0L0,124.8534393l0.3679028,261.8549805L223.9644623,512l225.5249176-125.8858948L449.12146,124.207901z M337.3320923,306.697052l-0.1458435-103.78685l-101.7059479,58.2168121l0.4860535,116.4644165l88.7288055-49.3442078l87.0138245,50.3450623L236.378891,476.4593506l-0.4125366-98.8679199l-12.0121307,6.6802368l-12.9728699-7.2966309l0.4143677,99.3448792L38.0288811,379.1746521l86.8948669-50.6029968l86.0576019,48.4033813l-0.4876404-116.9069519l-98.336441-55.2979431l0.1437073,102.2428284l-86.9916229,50.6594238L25.0255699,155.772995l87.1316986,48.9971466l12.6255341-21.561615l-88.6915741-49.8743134L211.4850769,35.548584v99.1400757l-86.7022705,48.5198669l99.1384735,55.7489929l99.1569977-56.757843l-86.6110077-47.5976868V35.4388657l174.6773529,96.3513412l-88.0663452,50.4094696l14.1079712,20.7105255l86.9942322-49.7958374l0.2851562,203.9970245L337.3320923,306.697052z" />
      </svg>
    </Box>
  );
};

export default WebpackIcon;
