import React from "react";
import { Box } from "grommet";

const ReduxIcon = () => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        width="1em"
        height="1em"
        style={{ transform: "rotate(360deg)" }}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          d="M16.633 16.504c.869-.075 1.543-.84 1.499-1.754c-.046-.914-.795-1.648-1.708-1.648h-.061a1.71 1.71 0 0 0-1.648 1.769c.03.479.226.869.494 1.153c-1.048 2.038-2.621 3.536-5.004 4.795c-1.603.838-3.296 1.154-4.944.929c-1.378-.194-2.456-.81-3.116-1.798c-.988-1.499-1.078-3.116-.255-4.734c.601-1.169 1.499-2.023 2.099-2.443a9.96 9.96 0 0 1-.42-1.542C-.867 14.408-.416 18.752.932 20.805c1.004 1.498 3.057 2.456 5.304 2.456c.599 0 1.229-.044 1.843-.194c3.896-.749 6.847-3.086 8.54-6.532l.014-.031zm5.348-3.746c-2.321-2.727-5.738-4.225-9.634-4.225h-.51c-.253-.554-.837-.899-1.497-.899h-.045c-.943 0-1.678.81-1.647 1.753c.03.898.794 1.648 1.708 1.648h.074a1.69 1.69 0 0 0 1.498-1.049h.555c2.309 0 4.495.674 6.488 1.992c1.527 1.004 2.622 2.322 3.236 3.896c.538 1.288.509 2.547-.045 3.597c-.854 1.647-2.293 2.517-4.195 2.517c-1.199 0-2.367-.375-2.967-.644c-.359.298-.959.793-1.394 1.093c1.318.598 2.652.943 3.94.943c2.922 0 5.093-1.647 5.918-3.236c.898-1.798.824-4.824-1.469-7.416l-.014.03zM6.49 17.042a1.724 1.724 0 0 0 1.708 1.648h.06a1.688 1.688 0 0 0 1.648-1.768c0-.899-.779-1.647-1.693-1.647h-.061c-.06 0-.149 0-.225.029c-1.243-2.098-1.768-4.346-1.572-6.771c.119-1.828.719-3.417 1.797-4.735c.899-1.124 2.592-1.679 3.746-1.708c3.236-.061 4.585 3.971 4.689 5.574l1.498.449c-.345-4.914-3.4-7.492-6.322-7.492c-2.742 0-5.273 1.993-6.293 4.915c-1.393 3.896-.479 7.641 1.229 10.638c-.149.195-.239.539-.209.868z"
          fill="#626262"
        />
        <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
      </svg>
    </Box>
  );
};

export default ReduxIcon;
